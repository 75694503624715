@tailwind components;
@tailwind utilities;

body {
    background-color: rgba(205, 205, 205, 0.1);
    margin: 0;
    line-height: inherit;
}

* {
    font-family: "Poppins", sans-serif !important;
}

.raffle {
    font-family: monospace !important;
    margin-left: 10px;
    margin-bottom: 100px;
}

.raffle h4 {
    font-family: monospace !important;
    font-weight: normal;
}
.InovuaReactDataGrid--theme-green-light, .InovuaReactDataGrid__column-header--show-border-right, .InovuaReactDataGrid__column-header--show-border-left{
    border: none !important;
}

.InovuaReactDataGrid--theme-green-light .InovuaReactDataGrid__column-header__resize-wrapper--show-border-left{
    border-left: none !important;
}